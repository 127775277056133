import GenericBarChart from "~/routes/management.scoreboard/chart-generic-bar";

type Props = {
  className?: string;
  height?: string;
};
export default function RecentCollectionsGauge({
  className,
  height = "251px"
}: Props) {
  return (
    <GenericBarChart
      className={className}
      barLabel="Collected"
      chartLabel="Collections"
      goalField="Revenue (Collected): Goal"
      actualField="Revenue (Collected): Actual"
      targetField="Revenue (Collected): Target"
      format="Accounting"
      height={height}
    />
  );
}
